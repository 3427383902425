import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import baseAPI from "../../../apis/baseAPI";

//------------------------------
// Action to Redirect
//------------------------------
const resetCommentAction = createAction("comment/reset");

//------------------------------
// Create Comment
//------------------------------
export const createCommentAction = createAsyncThunk(
  "comment/create",
  async (comment, { rejectWithValue, getState, dispatch }) => {
    const users = getState()?.users;
    const { userAuth } = users;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await baseAPI.post(
        "/comments",
        {
          description: comment?.description,
          postId: comment?.postId,
        },
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response.data);
    }
  }
);

//------------------------------
// Update Comment
//------------------------------
export const updateCommentAction = createAsyncThunk(
  "comment/update",
  async (comment, { rejectWithValue, getState, dispatch }) => {
    const users = getState()?.users;
    const { userAuth } = users;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await baseAPI.patch(
        `/comments/${comment.id}`,
        {
          description: comment?.description,
        },
        config
      );

      dispatch(resetCommentAction());

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response.data);
    }
  }
);
//------------------------------
// fetch detail Comment
//------------------------------
export const fetchCommentAction = createAsyncThunk(
  "comment/fetch",
  async (id, { rejectWithValue, getState, dispatch }) => {
    const users = getState()?.users;
    const { userAuth } = users;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await baseAPI.get(
        `/comments/${id}`,

        config
      );

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response.data);
    }
  }
);
//------------------------------
// Delete Comment
//------------------------------
export const deleteCommentAction = createAsyncThunk(
  "comment/delete",
  async (commentId, { rejectWithValue, getState, dispatch }) => {
    const users = getState()?.users;
    const { userAuth } = users;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await baseAPI.delete(
        `/comments/${commentId}`,

        config
      );

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response.data);
    }
  }
);

const commentSlices = createSlice({
  name: "comment",
  initialState: {},
  extraReducers: (builder) => {
    //------------------------------
    // Create Comment
    //------------------------------

    builder.addCase(createCommentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createCommentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.commentCreated = action?.payload;
      state.serverError = undefined;
      state.appError = undefined;
    });
    builder.addCase(createCommentAction.rejected, (state, action) => {
      state.loading = false;
      state.commentCreated = undefined;
      state.serverError = action?.error?.message;
      state.appError = action?.payload?.error;
    });
    //------------------------------
    // Update Comment
    //------------------------------

    builder.addCase(updateCommentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetCommentAction, (state, action) => {
      state.isUpdated = true;
    });
    builder.addCase(updateCommentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.commentUpdated = action?.payload;
      state.isUpdated = false;
      state.serverError = undefined;
      state.appError = undefined;
    });
    builder.addCase(updateCommentAction.rejected, (state, action) => {
      state.loading = false;
      state.commentUpdated = undefined;
      state.serverError = action?.error?.message;
      state.appError = action?.payload?.error;
    });
    //------------------------------
    // fetch detail Comment
    //------------------------------

    builder.addCase(fetchCommentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCommentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.commentDetails = action?.payload;
      state.serverError = undefined;
      state.appError = undefined;
    });
    builder.addCase(fetchCommentAction.rejected, (state, action) => {
      state.loading = false;
      state.commentDetails = undefined;
      state.serverError = action?.error?.message;
      state.appError = action?.payload?.error;
    });
    //------------------------------
    // Delete Comment
    //------------------------------

    builder.addCase(deleteCommentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCommentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.commentDeleted = action?.payload;
      state.serverError = undefined;
      state.appError = undefined;
    });
    builder.addCase(deleteCommentAction.rejected, (state, action) => {
      state.loading = false;
      state.commentDeleted = undefined;
      state.serverError = action?.error?.message;
      state.appError = action?.payload?.error;
    });
  },
});

export default commentSlices.reducer;
